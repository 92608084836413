// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  products: null,
  product: null,
  productByTitle: null,
  categories: null,
};

const getters = {};

const actions = {
  async getProducts({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_products/?category_id=' + id);

      if (response.data && response.data.data) {
        commit('setProducts', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getProductStock({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_products/?category_id=' + id);

      if (response.data && response.data.data) {
        commit('setProducts', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getProduct({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const url = `get_product/?id=${body.id}${body.city_id ? `&city_id=${body.city_id}` : ''}`;

      const response = await axiosInstance.get(url);

      if (response.data && response.data.data) {
        commit('setProduct', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getProductByTitle({ commit }, name) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_products_by_title?title= ' + name);

      if (response.data && response.data.data) {
        commit('setProductByTitle', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getCategories({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_product_categories');

      if (response.data && response.data.data) {
        commit('setCategories', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setProducts(state, data) {
    state.products = data;
  },
  setProduct(state, data) {
    state.product = data;
  },
  setProductByTitle(state, data){
    state.productByTitle = data;
  },
  setCategories(state, data) {
    state.categories = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
