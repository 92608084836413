<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__up">
          <div class="footer__description">
            <div class="logo">
              <img src="../../assets/images/logo-white.svg" alt="">
            </div>
            <p class="gray-200 text-md regular">
              {{ $t('footer.description') }}
            </p>
          </div>
          <div class="footer__row">
            <div class="footer__column">
              <p class="suptitle">
                {{ $t('footer.aboutUs') }}
              </p>
              <router-link to="/about">
                {{ $t('company') }}
              </router-link>
              <router-link to="/partners">
                {{ $t('partnership') }}
              </router-link>
            </div>
            <div class="footer__column">
              <p class="suptitle">
                {{ $t('footer.contacts') }}
              </p>
              <a href="tel:+77071651657">
                +7 707 165 16 57
              </a>
              <a href="tel:+77071651655">
                +7 707 165 16 55
              </a>
              <router-link to="#">
                {{ $t('footer.address') }}
              </router-link>
            </div>
            <div class="footer__column">
              <p class="suptitle">
                {{ $t('footer.companyInfo') }}
              </p>
              <a href="/Пользовательское_соглашение (1).pdf" target="_blank">
                {{ $t('footer.userAgreement') }}
              </a>
              <a target="_blank" href="/Политика_конфиденциальности_Шаблон_2.pdf">
                {{ $t('footer.privacyPolicy') }}
              </a>
              <a target="_blank" href="/Договор об оказании услуги (1).pdf">
                {{ $t('footer.serviceContract') }}
              </a>
              <a href="/Условия_покупки (1).pdf" target="_blank">
                {{ $t('footer.purchaseTerms') }}
              </a>
            </div>
          </div>
        </div>
        <div class="footer__down">
          <p class="gray-300 text-md regular">
            {{$t('footer.copyright')}}
          </p>
          <div class="socials">
            <a target="_blank" class="cursor-pointer" href="https://wa.me/77071651655">
              <img style="width: 28px; height: 28px" src="../../assets/images/whatsapp.svg" alt="">
            </a>
            <a target="_blank" href="https://t.me/Solwell02">
              <img src="../../assets/images/telegram.svg" alt="">
            </a>
            <a target="_blank" href="https://www.instagram.com/solwell_kz/">
              <img src="../../assets/images/instagram.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>