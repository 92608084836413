<template>
  <div class="burger-menu">
    <ul>
      <li
        v-for="(item, i) in lists"
        :key="i"
        @click="redirect(item.path)"
        class="text-lg medium gray-900"
      >
        {{ $t(item.title) }}
      </li>
    </ul>
    <div class="left burger-option">
      <div class="city cursor-pointer" @click="selectCityModal">
        <img src="../../assets/images/location.svg" alt="" />
        <span class="text-md semibold gray-blue-900">
          {{ cityName(cityId) }}
        </span>
      </div>
      <div class="language">
        <div
          @click="languageDropDown = !languageDropDown"
          class="language__active gray-blue-900 text-md semibold cursor-pointer"
        >
          {{ language.title }}
          <img
            :class="{ active: languageDropDown }"
            src="../../assets/images/chevron-down-header.svg"
            alt=""
          />
        </div>
        <ul class="language__list" v-if="languageDropDown">
          <li
            @click="changeLanguage(item)"
            class="cursor-pointer"
            v-for="(item, i) in languages"
            :key="i"
          >
            <span class="text-md medium gray-900">
              {{ item.title }}
            </span>
            <img
              v-if="item.code === language.code"
              src="@/assets/images/check.svg"
              alt=""
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
  <select-city
    v-if="settingsState.citySelectModal"
    @closeModal="settingsState.citySelectModal = false"
  />
</template>

<script setup>
import { ref, defineEmits, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { SUPPORT_LOCALES as supportLocales, setI18nLanguage } from "@/helpers/i18n";
import SelectCity from "../partials/Modals/SelectCity.vue";

const router = useRouter();

const emits = defineEmits(["closeBurger"]);

const languageDropDown = ref(false);

const store = useStore();

const { t, locale } = useI18n({ useScope: "global" });

const language = ref({
  title: "Русский",
  code: "RU",
});

const languages = ref([
  {
    title: "Қазақша",
    code: "kk",
  },
  {
    title: "Русский",
    code: "ru",
  },
  {
    title: "English",
    code: "en",
  },
]);

const countries = computed(() => store.state.global.countries);

const cityId = computed(() => store.state.global.selectedCity);

const lists = ref([
  {
    title: "main",
    path: "/",
  },
  {
    title: "catalog",
    path: "/catalog",
  },
  {
    title: "company",
    path: "/about",
  },
  {
    title: "blog",
    path: "/blog",
  },
  {
    title: "partnership",
    path: "/partners",
  },
  {
    title: "clients",
    path: "/clients",
  },
  {
    title: "school",
    path: "/school",
  },
]);

const changeLanguage = (item) => {
  language.value = item;
  setI18nLanguage(item.code);
  localStorage.setItem("selectedLanguage", JSON.stringify(item));
  languageDropDown.value = !languageDropDown.value;
};

const settingsState = ref({
  authModal: false,
  searchState: false,
  citySelectModal: false,
  basketModal: false,
  burgerMenu: false,
});

const redirect = (event) => {
  if (event) {
    router.push(event);
    emits("closeBurger");
  }
};

const cityName = (id) => {
  if (id && countries.value) {
    const country = countries.value.find((el) => el.title === "Казахстан");
    if (country && country.cities) {
      const city = country.cities.find((city) => city.id == id);
      return city ? city.title : "";
    }
  }

  return "";
};

const selectCityModal = () => {
  settingsState.value.citySelectModal = !settingsState.value.citySelectModal;
};
</script>

<style lang="scss">
.burger-option {
  border-top: 1px solid #e0e0e0;
  padding-top: 24px;
  .language {
    margin-top: 16px;
  }
  .city {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
